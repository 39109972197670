import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonButtons,
  IonButton,
  IonIcon,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  useIonAlert,
  IonFooter,
} from '@ionic/react'
import { logOutOutline } from 'ionicons/icons'

import { signOut } from 'firebase/auth'

import { useHistory } from 'react-router'
import { auth } from '../services/firebase'

import useStore from '../store/useStore'
import useCustomerStore from '../store/useCustomerStore'

import CONFIG from '../config/config'

const Account = () => {
  const history = useHistory()

  const user = useStore(state => state.user)

  const [presentLogoutConfirmation] = useIonAlert()

  const performSignOut = async () => {
    signOut(auth)
    useCustomerStore.getState().clearData()

    history.replace('/login')
  }

  const handleLogout = () => {
    presentLogoutConfirmation({
      cssClass: 'my-css',
      header: 'Sign Out',
      message: 'Are you sure you want to sign out?',
      buttons: [
        'Cancel',
        {
          text: 'Yes, sign out',
          handler: d => {
            performSignOut()
          },
        },
      ],
      onDidDismiss: e => console.log('did dismiss'),
    })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings</IonTitle>
          <IonButtons slot="primary">
            <IonButton
              color="secondary"
              onClick={() => {
                handleLogout()
              }}
            >
              <IonIcon slot="icon-only" icon={logOutOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>{user?.email}</IonCardSubtitle>
            <IonCardTitle>
              {user?.firstName} {user?.lastName}
            </IonCardTitle>
          </IonCardHeader>
        </IonCard>
      </IonContent>
      <IonFooter>
        <IonItem lines="none">
          <IonText>
            <p className="smallText">App Version: {CONFIG.appVersion}</p>
          </IonText>
        </IonItem>
      </IonFooter>
    </IonPage>
  )
}

export default Account
