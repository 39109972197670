const CONFIG = {
  firebaseConfig: {
    apiKey: 'AIzaSyBXPTJTh1ZFCrg9NejZDy7avllV2g5DF9o',
    authDomain: 'oyeapp-79699.firebaseapp.com',
    projectId: 'oyeapp-79699',
    storageBucket: 'oyeapp-79699.appspot.com',
    messagingSenderId: '775714586523',
    appId: '1:775714586523:web:38a1b9ecb4a85584072b3e',
    measurementId: 'G-X85Y9WQEC0',
  },
  domain: 'https://admin.oye-app.com', //production
  // domain: 'http://localhost:3000', //development
  appVersion: '20230428-1900',
}
// domain: "https://admin.oye-app.com", //production
//domain: "http://localhost:8100", //development

export default CONFIG
