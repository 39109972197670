import create from 'zustand'
import db from './indexedDB'
import _ from 'lodash'
import { Timestamp, query, orderBy, where, getDocs, onSnapshot } from 'firebase/firestore'

import { customersCollectionRef } from '../services/firebase'

//timestamp for 2021-01-01
const REASONABLE_STARTING_TS = 1609459200000

// const REASONABLE_STARTING_TS = 0

const useCustomerStore = create((set, get) => ({
  customers: [],
  lastSyncTimestamp: REASONABLE_STARTING_TS,
  unsubscribe: null,
  setUnsubscribe: unsubscribe => set({ unsubscribe }),
  setCustomers: customers => set({ customers }),
  setLastSyncTimestamp: timestamp => set({ lastSyncTimestamp: timestamp }),
  addCustomers: newCustomers => {
    set(state => {
      const customerMap = new Map(state.customers.map(customer => [customer.id, customer]))

      newCustomers.forEach(newCustomer => {
        if (customerMap.has(newCustomer.id)) {
          customerMap.set(newCustomer.id, { ...customerMap.get(newCustomer.id), ...newCustomer })
        } else {
          customerMap.set(newCustomer.id, newCustomer)
        }
      })

      return { customers: Array.from(customerMap.values()) }
    })
  },

  findCustomerByPhoneOrName: searchText => {
    if (!searchText || searchText.length < 3) {
      return []
    }
    let results = []

    // Check if searchText contains only digits
    if (/^\d+$/.test(searchText)) {
      results = get().customers.filter(customer => customer.phone.toLowerCase().includes(searchText.toLowerCase()))
    } else {
      results = get().customers.filter(customer => customer.fullName.toLowerCase().includes(searchText.toLowerCase()))
    }

    return results
  },
  getCustomerById: customerId => {
    return _.find(get().customers, { id: customerId })
  },

  getCustomerByPhone: phone => {
    return _.find(get().customers, { phone })
  },
  getCustomerByOfficialId: officialId => {
    return _.find(get().customers, { officialId })
  },
  // Add this new function to handle new customer updates
  handleNewCustomers: async (newCustomers, lastSyncTimestamp) => {
    get().addCustomers(newCustomers)

    // Update IndexedDB
    try {
      await db.customers.bulkPut(newCustomers)
    } catch (error) {
      console.error('Error saving all customers to IndexedDB:', error)
    }

    // Update lastSyncTimestamp in Zustand store and IndexedDB
    const latestTimestamp = newCustomers.reduce((max, customer) => {
      return Math.max(max, customer.updatedAt || 0)
    }, lastSyncTimestamp)

    if (latestTimestamp > lastSyncTimestamp) {
      console.log('Updating lastSyncTimestamp', latestTimestamp)
      get().setLastSyncTimestamp(latestTimestamp)
      db.settings
        .put({ key: 'lastSyncTimestamp', value: latestTimestamp })
        .catch(error => console.error('Error updating lastSyncTimestamp in IndexedDB:', error))

      // Unsubscribe from the previous listener and set up a new one
      if (get().unsubscribe) {
        get().unsubscribe()
      }
      const newUnsubscribe = get().setupQueryListener(latestTimestamp)
      get().setUnsubscribe(newUnsubscribe)
    }
  },
  loadAllCustomers: async () => {
    const q = query(customersCollectionRef, orderBy('createdAt', 'asc'))

    const querySnapshot = await getDocs(q)
    console.log('Number of initilial batch', querySnapshot.docs.length)
    const allCustomers = querySnapshot.docs.map(doc => {
      const data = doc.data()
      return {
        id: doc.id,
        ...data,
        createdAt: data.createdAt ? data.createdAt.toMillis() : REASONABLE_STARTING_TS,
        updatedAt: data.updatedAt ? data.updatedAt.toMillis() : data.createdAt.toMillis(),
      }
    })
    console.log('Number of initilial batch customers parsed', allCustomers.length)

    get().setCustomers(allCustomers)

    // Save allCustomers to IndexedDB
    try {
      await db.customers.bulkPut(allCustomers)
    } catch (error) {
      console.error('Error saving all customers to IndexedDB:', error)
    }
    // Update lastSyncTimestamp based on the newCustomers createdAt
    const latestTimestamp = allCustomers.reduce((max, customer) => {
      return Math.max(max, customer.updatedAt || 0)
    }, get().lastSyncTimestamp)

    if (latestTimestamp > get().lastSyncTimestamp) {
      get().setLastSyncTimestamp(latestTimestamp)
      db.settings.put({ key: 'lastSyncTimestamp', value: latestTimestamp })
    }
  },
  setupQueryListener: lastSyncTimestamp => {
    const lastSyncTimestampFirestore = Timestamp.fromMillis(lastSyncTimestamp)

    const q = query(
      customersCollectionRef,
      where('updatedAt', '>', lastSyncTimestampFirestore),
      orderBy('updatedAt', 'asc')
    )

    const unsubscribe = onSnapshot(q, async querySnapshot => {
      console.log('New customers data loaded', querySnapshot.docs.length)

      const newCustomers = querySnapshot.docs.map(doc => {
        const data = doc.data()
        return {
          id: doc.id,
          ...data,
          createdAt: data.createdAt ? data.createdAt.toMillis() : REASONABLE_STARTING_TS,
          updatedAt: data.updatedAt ? data.updatedAt.toMillis() : data.createdAt.toMillis(),
        }
      })

      if (newCustomers.length) {
        await get().handleNewCustomers(newCustomers, lastSyncTimestamp) // Use the new function here
      }
    })

    return () => unsubscribe()
  },
  loadAllCustomersFromIndexedDBToZustand: async () => {
    const allCustomers = await db.customers.toArray()
    console.log('Number of customer loaded from local', allCustomers.length)
    get().setCustomers(allCustomers)
  },

  initialize: async () => {
    await get().loadAllCustomersFromIndexedDBToZustand()
    // Load lastSyncTimestamp from IndexedDB
    const storedTimestamp = await db.settings.get('lastSyncTimestamp')
    if (storedTimestamp && storedTimestamp.value > REASONABLE_STARTING_TS) {
      get().setLastSyncTimestamp(storedTimestamp.value)
    } else {
      // If no lastSyncTimestamp is stored, set it to 0
      get().setLastSyncTimestamp(REASONABLE_STARTING_TS)
      db.settings.put({ key: 'lastSyncTimestamp', value: REASONABLE_STARTING_TS })

      // Load all customers from Firestore and save them internally
      await get().loadAllCustomers()
    }

    const lastSyncTimestamp = get().lastSyncTimestamp
    const unsubscribe = get().setupQueryListener(lastSyncTimestamp)
    get().setUnsubscribe(unsubscribe)

    // Return a cleanup function to unsubscribe from the Firestore listener
    return () => {
      if (get().unsubscribe) {
        get().unsubscribe()
      }
    }
  },

  clearData: async () => {
    // Clear all customers in IndexedDB
    await db.customers.clear()

    // Clear lastSyncTimestamp in IndexedDB
    await db.settings.delete('lastSyncTimestamp')

    // Clear the customers state in the store
    set({
      customers: [],
      lastSyncTimestamp: REASONABLE_STARTING_TS,
      unsubscribe: null,
    })
  },
}))

export default useCustomerStore
