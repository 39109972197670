import { Redirect, Route } from 'react-router-dom'

import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react'

import { alarmOutline, settingsOutline, storefrontOutline, peopleOutline } from 'ionicons/icons'

// import Organizations from './pages/Organizations'
import Account from './pages/Account'
import Balances from './pages/Balances'
import CustomerDetails from './pages/CustomerDetails'
import GasStations from './pages/GasStations'
import Users from './pages/Users'
import Customers from './pages/Customers'

const Tabs = () => {
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path="/app/customers/:balanceId">
          <CustomerDetails />
        </Route>
        <Route exact path="/app/customers">
          <Customers />
        </Route>
        <Route exact path="/app/stations">
          <GasStations />
        </Route>
        <Route path="/app/todo/:balanceId">
          <CustomerDetails />
        </Route>
        <Route exact path="/app/todo">
          <Balances />
        </Route>

        <Route path="/app/agents">
          <Users />
        </Route>

        <Route path="/app/settings">
          <Account />
        </Route>

        <Route exact path="/app">
          <Redirect to="/app/todo" />
        </Route>
      </IonRouterOutlet>

      <IonTabBar slot="bottom">
        <IonTabButton tab="todo" href="/app/todo">
          <IonIcon icon={alarmOutline} />
          <IonLabel>To do</IonLabel>
        </IonTabButton>
        <IonTabButton tab="customers" href="/app/customers">
          <IonIcon icon={peopleOutline} />
          <IonLabel>Customers</IonLabel>
        </IonTabButton>
        <IonTabButton tab="stations" href="/app/stations">
          <IonIcon icon={storefrontOutline} />
          <IonLabel>Gas stations</IonLabel>
        </IonTabButton>
        <IonTabButton tab="agents" href="/app/agents">
          <IonIcon icon={peopleOutline} />
          <IonLabel>Marshalls</IonLabel>
        </IonTabButton>

        <IonTabButton tab="settings" href="/app/settings">
          <IonIcon icon={settingsOutline} />
          <IonLabel>Settings</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  )
}

export default Tabs
