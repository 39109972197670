import { useEffect } from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'

import { IonApp, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { auth } from './services/firebase'

import Login from './pages/Login'
import SignInWithEmailLink from './pages/SignInWithEmailLink'
import Tabs from './Tabs'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

/* Global CSS */
import './global.css'

import useStore from './store/useStore'
import { useAuthState } from 'react-firebase-hooks/auth'

import { setupIonicReact } from '@ionic/react'

setupIonicReact()

const LOGIN_PATH = '/login'

function PrivateRoute({ children, ...rest }) {
  const [user, loading] = useAuthState(auth)
  const location = useLocation()

  if (loading || location.pathname === LOGIN_PATH) {
    return null
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user?.uid ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

const App = () => {
  const loadUserAndOrganization = useStore(state => state.loadUserAndOrganization)
  const unsubscribeListeners = useStore(state => state.unsubscribeListeners)
  const [user, loading] = useAuthState(auth)

  useEffect(() => {
    if (user?.uid) {
      loadUserAndOrganization(user.uid)
    }
    return unsubscribeListeners
  }, [loadUserAndOrganization, user?.uid, unsubscribeListeners])

  if (loading) {
    return null
  }
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path={LOGIN_PATH}>
            <Login />
          </Route>
          <Route exact path="/sign-in-with-email-link">
            <SignInWithEmailLink />
          </Route>
          <Route exact={true} path="/" render={() => <Redirect to="/app" />} />
          <PrivateRoute path="/app">
            <Tabs />
          </PrivateRoute>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  )
}

export default App
