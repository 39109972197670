import { useMemo, useState } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonButton,
  useIonModal,
  IonIcon,
  useIonToast,
  IonButtons,
  IonItemDivider,
  IonInput,
  IonCard,
} from '@ionic/react'
import { add, close, personOutline } from 'ionicons/icons'
import { query, where, getDocs, addDoc, serverTimestamp, orderBy } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'

import { useForm } from 'react-hook-form'

import { usersCollectionRef } from '../services/firebase'

import useStore from '../store/useStore'

const AddUserModal = ({ onDismiss, onSave, isSaving }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    // shouldFocusError: true,
    // shouldUseNativeValidation: true,
    // delayError: true,
  })

  const onSubmit = data => {
    onSave(data)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="secondary">
            <IonButton
              color="secondary"
              onClick={() => {
                onDismiss({})
              }}
            >
              <IonIcon slot="icon-only" icon={close} />
            </IonButton>
          </IonButtons>
          <IonTitle>Add Marshall</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="ion-padding">
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonCard className="ion-padding">
            <IonItem
              className="ion-padding-horizontal ion-margin-top"
              fill="outline"
              color={errors?.firstName ? 'danger' : 'primary'}
            >
              <IonLabel position="floating" color={errors.firstName ? 'danger' : 'primary'}>
                {errors?.firstName ? errors?.firstName?.message : 'First name'}
              </IonLabel>
              <IonInput
                required
                {...register('firstName', { required: 'First name is required' })}
                placeholder="John"
                autocomplete="off"
              ></IonInput>
            </IonItem>
            <IonItem
              className="ion-padding-horizontal ion-margin-top"
              fill="outline"
              color={errors?.lastName ? 'danger' : 'primary'}
            >
              <IonLabel position="floating" color={errors.lastName ? 'danger' : 'primary'}>
                {errors?.lastName ? errors?.lastName?.message : 'Last name'}
              </IonLabel>
              <IonInput
                required
                {...register('lastName', { required: 'Last name is required' })}
                placeholder="Doe"
                autocomplete="off"
              ></IonInput>
            </IonItem>
            <IonItem
              className="ion-padding-horizontal ion-margin-top ion-margin-bottom"
              fill="outline"
              color={errors?.email ? 'danger' : 'primary'}
            >
              <IonLabel position="floating" color={errors.email ? 'danger' : 'primary'}>
                {errors?.email ? errors?.email?.message : 'Email'}
              </IonLabel>
              <IonInput
                required
                {...register('email', { required: 'Email is required (sign-in)' })}
                placeholder="john@oye-app.com"
                type="email"
                autocomplete="off"
              ></IonInput>
            </IonItem>

            <IonButton disabled={isSaving} className="ion-margin" expand="block" type="submit">
              {isSaving ? 'Saving...' : 'Add Marshall'}
            </IonButton>
          </IonCard>
        </form>
      </IonContent>
    </IonPage>
  )
}
/**
 *
 * Users
 */
const Users = () => {
  const user = useStore(state => state.user)
  const [saving, setSaving] = useState(false)

  console.log({ user })

  const adminUsersQuery = useMemo(() => {
    return user?.isSuperAdmin
      ? query(
          usersCollectionRef,
          where('isSuperAdmin', '!=', true),
          orderBy('isSuperAdmin', 'asc'),
          orderBy('disabled', 'asc')
        )
      : undefined
  }, [user])

  const [adminUsers] = useCollectionData(adminUsersQuery, {
    idField: 'id',
  })

  const [presentToast] = useIonToast()

  const handleDismiss = () => {
    dismiss()
  }

  const handleSave = async data => {
    setSaving(true)
    const userEmailQuery = query(usersCollectionRef, where('email', '==', data.email))
    const querySnapshot = await getDocs(userEmailQuery)

    if (querySnapshot.docs.length > 0) {
      presentToast({
        color: 'danger',
        message: 'User with this email already exists',
        duration: 2000,
        position: 'bottom',
      })
    } else {
      await addDoc(usersCollectionRef, {
        ...data,
        email: data.email.toLowerCase().trim(),
        isSuperAdmin: false,
        disabled: false,
        createdAt: serverTimestamp(),
        createdBy: user?.id,
      })
      handleDismiss()
      setTimeout(() => {
        presentToast({
          color: 'success',
          message: 'Marshall added',
          duration: 2000,
        })
      }, 500)
    }

    setSaving(false)
  }

  const [present, dismiss] = useIonModal(AddUserModal, {
    onDismiss: handleDismiss,
    onSave: handleSave,
    isSaving: saving,
  })

  /**
   * We need to include a loader, for when someone logs in, and the page content is loading
   */
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle slot="start">Marshalls</IonTitle>
          <IonButton
            slot="end"
            size="small"
            fill="solid"
            shape="round"
            color="tertiary"
            onClick={() => {
              present({})
            }}
          >
            <IonIcon color="secondary" icon={add} />
            <IonText color="secondary" className="top-right-button">
              Add
            </IonText>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList lines="inset">
          {adminUsers?.map(user => (
            <IonItem
              key={user.id}
              onClick={() => {
                // presentEditModal()
              }}
              slot="start"
            >
              <IonIcon icon={personOutline} slot="start" />
              <IonLabel className="ion-text-wrap">
                <IonText color="dark">
                  <h2>
                    {user.firstName} {user.lastName}
                  </h2>
                </IonText>
                <IonText color="medium">
                  <h3>{user?.email}</h3>
                </IonText>
                {user.disabled && (
                  <IonText color="gray">
                    <h4 className="bold">Disabled</h4>
                  </IonText>
                )}
              </IonLabel>

              {/* <IonButton
                slot="end"
                disabled={currentGasStation?.id && gasStation.id === currentGasStation.id ? true : false}
                onClick={() => {
                  setGasStationAsDefault(gasStation.id)
                }}
              >
                {currentGasStation?.id && gasStation.id === currentGasStation.id ? 'Default' : 'Set as default'}
              </IonButton> */}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  )
}

export default Users
