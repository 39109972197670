import { useMemo, useState } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonLabel,
  IonItem,
  IonButtons,
  IonButton,
  IonIcon,
  useIonModal,
  useIonLoading,
  IonInput,
  IonItemDivider,
  IonSearchbar,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonListHeader,
  useIonToast,
} from '@ionic/react'
import { close, phonePortraitOutline, locationOutline, cashOutline, add } from 'ionicons/icons'

import { serverTimestamp, updateDoc, doc, addDoc } from 'firebase/firestore'

import { useForm } from 'react-hook-form'

import Fuse from 'fuse.js'

import _ from 'lodash'
import { formatPhoneNumberIntl } from 'react-phone-number-input/mobile'

import { gasStationsCollectionRef, usersCollectionRef } from '../services/firebase'
import useStore from '../store/useStore'

const locale = Intl.NumberFormat('ke-KE', {
  style: 'currency',
  currency: 'KES',
  maximumFractionDigits: 2,
})

const EditCurrentFuelPrice = ({ onDismiss, onSave, currentFuelPrice }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { currentFuelPrice: currentFuelPrice } }) // {defaultValues: product}
  const onSubmit = data => {
    onSave({ ...data })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="secondary">
              <IonButton
                color="secondary"
                onClick={() => {
                  onDismiss({})
                }}
              >
                <IonIcon slot="icon-only" icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>Edit fuel price</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonItemDivider />

          <IonItem>
            <IonLabel color={errors.currentFuelPrice ? 'danger' : undefined} position="stacked">
              Current fuel price at this station
            </IonLabel>
            <IonInput
              {...register('currentFuelPrice', { required: false, min: 0, valueAsNumber: true })}
              type="decimal"
              placeholder="Fuel price"
              inputmode="decimal"
            ></IonInput>
          </IonItem>

          <IonItemDivider />
          <IonButton expand="block" type="submit">
            Save Changes
          </IonButton>
        </IonContent>
      </IonPage>
    </form>
  )
}

const AddGasStationForm = ({ onDismiss, onSave, isSaving }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    // shouldFocusError: true,
    // shouldUseNativeValidation: true,
    delayError: true,
  })

  const onSubmit = data => {
    if (isSaving) {
      return
    }
    onSave(data)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="secondary">
            <IonButton
              color="secondary"
              onClick={() => {
                onDismiss({})
              }}
            >
              <IonIcon slot="icon-only" icon={close} />
            </IonButton>
          </IonButtons>
          <IonTitle>Add Gas Station</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding" fullscreen>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonCard className="ion-padding">
            <IonItem
              className="ion-padding-horizontal ion-margin-top"
              fill="outline"
              color={errors?.name ? 'danger' : 'primary'}
            >
              <IonLabel position="floating" color={errors?.name ? 'danger' : 'primary'}>
                {errors?.name ? errors?.name?.message : 'Name'}
              </IonLabel>
              <IonInput
                {...register('name', { required: 'Name is required' })}
                placeholder="Name"
                clearInput
              ></IonInput>
            </IonItem>
            <IonItem
              className="ion-padding-horizontal ion-margin-top ion-margin-bottom"
              fill="outline"
              color={errors?.address ? 'danger' : 'primary'}
            >
              <IonLabel position="floating" color={errors?.address ? 'danger' : 'primary'}>
                {errors?.address ? errors?.address?.message : 'Address'}
              </IonLabel>
              <IonInput
                clearInput
                placeholder="Address"
                {...register('address', { required: 'Address is required' })}
              ></IonInput>
            </IonItem>

            <IonItem
              className="ion-padding-horizontal ion-margin-top ion-margin-bottom"
              fill="outline"
              color={errors?.currentFuelPrice ? 'danger' : 'primary'}
            >
              <IonLabel color={errors.currentFuelPrice ? 'danger' : 'primary'} position="floating">
                Current fuel price at this station
              </IonLabel>
              <IonInput
                {...register('currentFuelPrice', { required: false, min: 0, valueAsNumber: true })}
                type="decimal"
                placeholder="Fuel price"
                inputmode="decimal"
              ></IonInput>
            </IonItem>

            <IonButton disabled={isSaving} className="ion-margin" expand="block" type="submit">
              {isSaving ? 'Saving...' : 'Add station'}
            </IonButton>
          </IonCard>
        </form>
      </IonContent>
    </IonPage>
  )
}

const GasStations = () => {
  const user = useStore(state => state.user)
  const gasStations = useStore(state => state.gasStations)
  const getGasStationById = useStore(state => state.getGasStationById)
  const [searchText, setSearchText] = useState()
  const [saving, setSaving] = useState(false)

  const handleSave = async data => {
    setSaving(true)

    try {
      await addDoc(gasStationsCollectionRef, {
        ...data,
        createdAt: serverTimestamp(),
        createdBy: user?.id,
      })
      handleDismiss()
      setTimeout(() => {
        presentToast({
          color: 'success',
          message: 'Station added successfully',
          duration: 2000,
        })
      }, 500)
    } catch (err) {
      presentToast({
        color: 'danger',
        message: 'Error, please try again. Check the network',
        duration: 2000,
        position: 'bottom',
      })
    } finally {
      setSaving(false)
    }
  }

  const handleDismiss = () => {
    dismiss()
  }

  // handle loader flow in modal ?
  const [present, dismiss] = useIonModal(AddGasStationForm, {
    onDismiss: handleDismiss,
    onSave: handleSave,
    isSaving: saving,
  })

  const searchObj = useMemo(() => {
    return new Fuse(gasStations, {
      keys: ['name', 'address'],
      minMatchCharLength: 3,
      threshold: 0.3,
    })
  }, [gasStations])

  const foundGasStations = useMemo(() => {
    if (searchText) {
      const results = searchObj.search(searchText)

      return results.map(el => el.item)
    } else {
      return gasStations
    }
  }, [searchText, searchObj, gasStations])

  const currentGasStation = useMemo(() => {
    if (user && gasStations?.length > 0 && user.currentGasStationId) {
      return getGasStationById(user.currentGasStationId)
    }
    return null
  }, [user, getGasStationById, gasStations])

  const handleEditCurrentPriceModalDismiss = () => {
    dismissEditModal()
  }

  const handleEditCurrentPrice = async editedData => {
    if (!currentGasStation) {
      return
    }
    try {
      presentLoader({
        message: 'Saving...',
      })
      let gasStationRef = doc(gasStationsCollectionRef, currentGasStation?.id)
      await updateDoc(gasStationRef, {
        currentFuelPrice: editedData.currentFuelPrice,
        currentFuelPriceUpdatedAt: serverTimestamp(),
        currentFuelPriceUpdatedBy: user.id,
      })

      setTimeout(() => {
        dismissLoader()
      }, 250)
      handleEditCurrentPriceModalDismiss()
      setTimeout(() => {
        presentToast({
          color: 'success',
          message: 'Changes saved successfully',
          duration: 2000,
        })
      }, 500)
    } catch (error) {
      dismissLoader()
      setTimeout(() => {
        presentToast({
          color: 'danger',
          message: 'Failed to save changes. Please try again.',
          duration: 2000,
        })
      }, 500)
    }
  }

  const setGasStationAsDefault = async gasStationId => {
    if (!gasStationId || !user?.id) {
      return
    }
    try {
      presentLoader({
        message: 'Saving...',
      })
      let meRef = doc(usersCollectionRef, user.id)
      await updateDoc(meRef, {
        currentGasStationId: gasStationId,
      })

      dismissLoader()
      setTimeout(() => {
        presentToast({
          color: 'success',
          message: 'Current station updated',
          duration: 2000,
        })
      }, 500)
    } catch (error) {
      dismissLoader()
      setTimeout(() => {
        presentToast({
          color: 'danger',
          message: 'Failed to save changes. Please try again.',
          duration: 2000,
        })
      }, 500)
    }
  }

  const [presentToast] = useIonToast()

  const [presentLoader, dismissLoader] = useIonLoading()

  const [presentEditCurrentFuelPrice, dismissEditModal] = useIonModal(EditCurrentFuelPrice, {
    onDismiss: handleEditCurrentPriceModalDismiss,
    onSave: handleEditCurrentPrice,
    currentFuelPrice: currentGasStation?.currentFuelPrice || 0,
  })

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Gas stations</IonTitle>
          <IonButton
            slot="end"
            size="small"
            fill="solid"
            shape="round"
            color="tertiary"
            onClick={() => {
              present({})
            }}
          >
            <IonIcon color="secondary" icon={add} />
            <IonText color="secondary" className="top-right-button">
              Add
            </IonText>
          </IonButton>
        </IonToolbar>

        <IonToolbar>
          <IonSearchbar value={searchText} onIonChange={e => setSearchText(e?.detail?.value)} animated></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {currentGasStation && (
          <IonCard>
            <IonCardHeader>
              <IonCardSubtitle>Current gas station</IonCardSubtitle>
              <IonCardTitle>{currentGasStation?.name}</IonCardTitle>
            </IonCardHeader>
            <IonItem lines="none">
              <IonIcon icon={cashOutline} slot="start" />
              <IonLabel className="ion-text-wrap">
                <IonText>
                  <p>Current fuel price</p>
                  <h4 className="bold">{locale.format(currentGasStation.currentFuelPrice)}/l</h4>
                </IonText>
              </IonLabel>
              <IonButton
                onClick={() => {
                  presentEditCurrentFuelPrice()
                }}
              >
                Update
              </IonButton>
            </IonItem>

            <IonItem lines="none">
              <IonIcon icon={locationOutline} slot="start" />
              <IonLabel className="ion-text-wrap">
                <IonText>
                  <p>Address</p>
                  <h4>{currentGasStation?.address}</h4>
                </IonText>
              </IonLabel>
            </IonItem>

            <IonItem lines="none">
              <IonIcon icon={phonePortraitOutline} slot="start" />
              <IonLabel className="ion-text-wrap">
                <IonText>
                  <p>Phone number</p>
                  <h4>{currentGasStation?.phone ? formatPhoneNumberIntl(currentGasStation?.phone) : ''}</h4>
                </IonText>
              </IonLabel>
            </IonItem>
          </IonCard>
        )}

        <IonList lines="inset">
          {foundGasStations?.map(gasStation => (
            <IonItem
              key={gasStation.id}
              onClick={() => {
                // presentEditModal()
              }}
            >
              <IonLabel className="ion-text-wrap">
                <IonText color="dark">
                  <h2>{gasStation.name}</h2>
                </IonText>
                <IonText color="medium">
                  <h3>{formatPhoneNumberIntl(gasStation?.phone)}</h3>
                  <h3>{gasStation.address}</h3>
                </IonText>
                <IonText color="primary">
                  <h4 className="bold">{locale.format(gasStation.currentFuelPrice)}/l</h4>
                </IonText>
              </IonLabel>

              {/* <IonButton
                slot="end"
                disabled={currentGasStation?.id && gasStation.id === currentGasStation.id ? true : false}
                onClick={() => {
                  setGasStationAsDefault(gasStation.id)
                }}
              >
                {currentGasStation?.id && gasStation.id === currentGasStation.id ? 'Default' : 'Set as default'}
              </IonButton> */}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  )
}

export default GasStations
