import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonItem,
  IonSearchbar,
  IonLoading,
} from '@ionic/react'
import { Virtuoso } from 'react-virtuoso'

import { formatPhoneNumberIntl } from 'react-phone-number-input/mobile'

import 'react-phone-number-input/style.css'

import useStore from '../store/useStore'
import useCustomerStore from '../store/useCustomerStore'

const Customers = () => {
  const user = useStore(state => state.user)
  const { customers, initialize, findCustomerByPhoneOrName } = useCustomerStore()
  const [searchText, setSearchText] = useState()

  useEffect(() => {
    const cleanup = initialize()

    // Clean up the Firestore listener on component unmount
    return () => {
      cleanup()
    }
  }, [initialize])

  const foundCustomers = useMemo(() => {
    if (searchText && searchText.length > 3 && customers.length > 0) {
      return findCustomerByPhoneOrName(searchText)
    }
    return []
  }, [findCustomerByPhoneOrName, searchText, customers])

  const getCustomer = useCallback(
    index => {
      return foundCustomers[index]
    },
    [foundCustomers]
  )

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Customers</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            placeholder="Search by phone or name"
            value={searchText}
            onIonChange={e => setSearchText(e?.detail?.value)}
            animated
          ></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <Virtuoso
          style={{ height: '100%' }}
          totalCount={foundCustomers ? foundCustomers.length : 0}
          itemContent={index => {
            return (
              // <div style={{ height: '100%' }}>
              <IonItem routerLink={`/app/customers/${getCustomer(index).id}`}>
                <IonLabel>
                  <h2>{getCustomer(index).fullName}</h2>
                  <h4>{formatPhoneNumberIntl(getCustomer(index).phone)}</h4>
                </IonLabel>
              </IonItem>
              // </div>
            )
          }}
        />
        {/* <IonList>
          {foundCustomers?.map(customer => (
            <IonItem key={customer.id} routerLink={`/app/customers/${customer.id}`}>
              <IonLabel>
                <h2>{customer.fullName}</h2>
                <h4>{formatPhoneNumberIntl(customer.phone)}</h4>
              </IonLabel>
            </IonItem>
          ))}
        </IonList> */}
        <IonLoading isOpen={!customers || customers.length === 0} message={'Loading customers...'} />
      </IonContent>
    </IonPage>
  )
}

export default Customers
