import { useCallback, useMemo, useState, Fragment, useEffect } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonLabel,
  IonItem,
  IonButtons,
  IonBackButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonText,
  IonListHeader,
  IonButton,
  useIonModal,
  IonIcon,
  IonInput,
  IonCardSubtitle,
  IonItemDivider,
  useIonToast,
  IonDatetime,
  useIonLoading,
} from '@ionic/react'

import { query, where, addDoc, serverTimestamp, orderBy, doc, updateDoc } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { formatPhoneNumberIntl } from 'react-phone-number-input/mobile'
import _ from 'lodash'
import {
  add,
  cashOutline,
  idCardOutline,
  phonePortraitOutline,
  calendarOutline,
  calendarClearOutline,
  close,
  receiptOutline,
  storefrontOutline,
} from 'ionicons/icons'
import { useParams } from 'react-router'
import phone from 'phone'
import { useForm, Controller } from 'react-hook-form'

import { useDocumentData } from 'react-firebase-hooks/firestore'

import { DateTime } from 'luxon'

import { defaultTimezone, defaultFuelAmountCoveredByInsuranPolicy } from '../constants/constants'

import {
  customersCollectionRef,
  transactionsCollectionRef,
  balancesCollectionRef,
  policiesCollectionRef,
} from '../services/firebase'
import useStore from '../store/useStore'
import useCustomerStore from '../store/useCustomerStore'
// import AddTransactionForm from '../components/AddTransactionForm'

const locale = Intl.NumberFormat('ke-KE', {
  style: 'currency',
  currency: 'KES',
  maximumFractionDigits: 2,
})

const litresLocale = Intl.NumberFormat('ke-KE', {
  style: 'unit',
  unit: 'liter',
  unitDisplay: 'long',
})

// const POLICY_DAYS = 29
const POLICY_DAYS = 89
const DATE_DISPLAY_FORMAT = 'd LLL, yyyy'

const AddTransactionForm = ({ onSave, fuelPrice, updateTrigger, saving }) => {
  const [amount, setAmount] = useState(0)

  const [presentToast] = useIonToast()

  const fuelAmount = useMemo(() => {
    if (amount > 0) {
      return parseFloat((amount / fuelPrice).toFixed(2))
    }
    return 0
  }, [amount, fuelPrice])

  const handleSave = useCallback(() => {
    if (saving) {
      return
    }
    if (amount > 0) {
      onSave({ amount, fuelAmount, fuelPrice })
    } else {
      presentToast({
        color: 'danger',
        message: `Amount can't be 0`,
        duration: 1000,
      })
    }
  }, [amount, fuelAmount, fuelPrice, onSave, presentToast, saving])

  const errors = {}
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Add transaction</IonCardTitle>
        <IonCardSubtitle>It'll be added for the current gas station</IonCardSubtitle>
      </IonCardHeader>

      <IonItem>
        <IonLabel position="stacked" color={errors.amount ? 'danger' : undefined}>
          Transaction amount in KES
        </IonLabel>
        <IonInput
          required
          name="amount"
          type="number"
          value={amount}
          onIonChange={e => {
            setAmount(e.detail.value ? parseFloat(parseFloat(e.detail.value).toFixed(2)) : 0)
          }}
          placeholder="Amount"
          autocomplete="off"
        />
      </IonItem>

      <IonItem lines="none">
        <IonText>
          <p>Amount of fuel calculated by current gas price</p>
          <h4>
            {fuelAmount} litres at {locale.format(fuelPrice)}/l
          </h4>
        </IonText>
      </IonItem>
      <IonCardContent>
        <IonButton
          expand="block"
          type="submit"
          disabled={saving}
          onClick={() => {
            handleSave()
          }}
        >
          {saving ? 'Saving...' : 'Save transaction'}
        </IonButton>
      </IonCardContent>
    </IonCard>
  )
}

const EditTransactionModal = ({ onDismiss, onSave, saving, transaction }) => {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      amount: transaction.amount || 0,
      fuelAmount: transaction.fuelAmount || 0,
      fuelPrice: transaction.fuelPrice || 0,
    },
    reValidateMode: 'onChange',
    // shouldFocusError: true,
    // shouldUseNativeValidation: true,
    // delayError: true,
  })

  const onSubmit = data => {
    const parsedData = {}
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const parsedValue = parseFloat(parseFloat(data[key]).toFixed(2))
        parsedData[key] = parsedValue
      }
    }

    onSave(parsedData)
  }

  const fuelPriceValue = watch('fuelPrice')
  const amountValue = watch('amount')

  useEffect(() => {
    if ((fuelPriceValue > 0) & (amountValue > 0)) {
      const newValue = parseFloat(parseFloat(amountValue / fuelPriceValue).toFixed(2))
      setValue('fuelAmount', newValue)
    } else {
      setValue('fuelAmount', 0)
    }
  }, [setValue, fuelPriceValue, amountValue])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="secondary">
            <IonButton
              color="secondary"
              onClick={() => {
                onDismiss({})
              }}
            >
              <IonIcon slot="icon-only" icon={close} />
            </IonButton>
          </IonButtons>
          <IonTitle>Update Transaction</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonItemDivider />

          <IonItem>
            <IonLabel position="stacked" color={errors.amount ? 'danger' : undefined}>
              Amount
            </IonLabel>
            <Controller
              render={field => {
                return (
                  <IonInput
                    name={field.field.name}
                    placeholder="Amount"
                    color="secondary"
                    autocomplete="off"
                    value={field.field.value}
                    type="number"
                    inputMode="numeric"
                    step="0.01"
                    onIonChange={e => {
                      setValue(
                        field.field.name,
                        e.detail.value ? parseFloat(parseFloat(e.detail.value).toFixed(2)) : 0,
                        {
                          shouldValidate: true,
                          shouldTouch: true,
                          shouldDirty: true,
                        }
                      )
                    }}
                  />
                )
              }}
              control={control}
              name="amount"
              rules={{ required: true }}
            />
          </IonItem>

          <IonItem>
            <IonLabel position="stacked" color={errors.amount ? 'danger' : undefined}>
              Fuel price
            </IonLabel>
            <Controller
              render={field => {
                return (
                  <IonInput
                    name={field.field.name}
                    placeholder="Fuel price"
                    color="secondary"
                    autocomplete="off"
                    type="number"
                    step="0.01"
                    inputMode="numeric"
                    value={field.field.value}
                    onIonChange={e => {
                      const parsedPrice = e.detail.value ? parseFloat(parseFloat(e.detail.value).toFixed(2)) : 0

                      setValue(field.field.name, parsedPrice, {
                        shouldValidate: true,
                        shouldTouch: true,
                        shouldDirty: true,
                      })
                    }}
                  />
                )
              }}
              control={control}
              name="fuelPrice"
              rules={{ required: true }}
            />
          </IonItem>

          <IonItem>
            <IonLabel position="stacked" color={errors.amount ? 'danger' : undefined}>
              Fuel amount
            </IonLabel>
            <Controller
              render={field => {
                return (
                  <IonInput
                    name={field.field.name}
                    placeholder="Fuel amount"
                    color="secondary"
                    autocomplete="off"
                    value={field.field.value}
                    type="number"
                    inputMode="numeric"
                    step="0.01"
                    disabled
                  />
                )
              }}
              control={control}
              name="fuelAmount"
              rules={{ required: true }}
            />
          </IonItem>

          <IonItemDivider />
          <IonButton expand="block" type="submit" disabled={saving}>
            {saving ? 'Saving...' : 'Save changes'}
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  )
}

const TransactionCard = ({ transaction, gasStation, onTransactionUpdate }) => {
  const [presentEditModal, dismissEditModal] = useIonModal(EditTransactionModal, {
    onDismiss: () => {
      dismissEditModal()
    },
    onSave: data => {
      onTransactionUpdate(transaction?.id, data, dismissEditModal)
    },
    transaction: transaction,
  })
  return (
    <IonItem key={transaction?.id}>
      <IonIcon icon={cashOutline} slot="start" />
      <IonLabel className="ion-text-wrap">
        <IonText>
          <p>Fuel / Transaction amount</p>
          <h4>
            {transaction?.fuelAmount}l / {locale.format(transaction?.amount)}
          </h4>
          <h4>
            {gasStation?.name} @ {locale.format(transaction?.fuelPrice)}
          </h4>
        </IonText>
      </IonLabel>
      <IonButton
        slot="end"
        onClick={() => {
          presentEditModal()
        }}
      >
        Edit
      </IonButton>
    </IonItem>
  )
}

const PolicyCard = ({ policy }) => {
  const startDateDisplay = useMemo(() => {
    const parsedDate = DateTime.fromJSDate(policy.startDate.toDate()).setZone(defaultTimezone)
    return parsedDate.toFormat(DATE_DISPLAY_FORMAT)
  }, [policy.startDate])

  const endDateDisplay = useMemo(() => {
    const parsedDate = DateTime.fromJSDate(policy.endDate.toDate()).setZone(defaultTimezone)
    return parsedDate.toFormat(DATE_DISPLAY_FORMAT)
  }, [policy.endDate])

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle>{policy?.policyNumber}</IonCardSubtitle>
      </IonCardHeader>
      <IonItem lines="none">
        <IonIcon icon={calendarClearOutline} slot="start" />
        <IonLabel className="ion-text-wrap">
          <IonText>
            <p>Start date</p>
            <h4 className="bold">{startDateDisplay}</h4>
          </IonText>
        </IonLabel>
      </IonItem>
      <IonItem lines="none">
        <IonIcon icon={calendarOutline} slot="start" />
        <IonLabel className="ion-text-wrap">
          <IonText>
            <p>End date</p>
            <h4 className="bold">{endDateDisplay}</h4>
          </IonText>
        </IonLabel>
      </IonItem>
    </IonCard>
  )
}
const AddPolicyModal = ({ onSave, onDismiss, minStartDate = new Date(), officialId }) => {
  const [startDate, setStartDate] = useState(minStartDate)
  const [showDatePicker, setShowDatePicker] = useState(false)

  //Calculate end date
  const endDate = useMemo(() => {
    let endDate = startDate.plus({ days: POLICY_DAYS })
    endDate = endDate.endOf('day')
    return endDate
  }, [startDate])

  // Calculate policy number
  const policyNumber = useMemo(() => {
    const parsedStart = startDate
    const parsedEnd = endDate
    const isTheSameYear = parsedStart.year === parsedEnd.year

    const days = parsedStart.toFormat('dd') + parsedEnd.toFormat('dd')
    const months = parsedStart.toFormat('MM') + parsedEnd.toFormat('MM')
    let year = parsedEnd.toFormat('yy')
    if (!isTheSameYear) {
      year = `${parsedStart.toFormat('yy')}${parsedEnd.toFormat('yy')}`
    }
    return `${officialId}-${days}-${months}-${year}`
  }, [startDate, endDate, officialId])

  const handleSave = useCallback(() => {
    const saveOb = { startDate: startDate.toJSDate(), endDate: endDate.toJSDate(), policyNumber }
    onSave(saveOb)
  }, [startDate, policyNumber, endDate, onSave])

  const formattedTextStartDate = useMemo(() => {
    return startDate.toFormat('yyyy-MM-dd')
  }, [startDate])

  const setStartDateFromPicker = useCallback(newDateString => {
    const parsedDate = DateTime.fromFormat(newDateString, 'yyyy-MM-dd').setZone(defaultTimezone)

    setStartDate(parsedDate)
  }, [])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="secondary">
            <IonButton
              color="secondary"
              onClick={() => {
                onDismiss({})
              }}
            >
              <IonIcon slot="icon-only" icon={close} />
            </IonButton>
          </IonButtons>
          <IonTitle>Add policy</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {showDatePicker && (
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Policy start date</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonDatetime
                presentation="date"
                value={formattedTextStartDate}
                onIonChange={e => setStartDateFromPicker(e.detail.value)}
              />
            </IonCardContent>
          </IonCard>
        )}
        <IonItem lines="none">
          <IonIcon icon={calendarClearOutline} slot="start" />
          <IonLabel className="ion-text-wrap">
            <IonText>
              <p>Start date</p>
              <h4>{startDate.toFormat(DATE_DISPLAY_FORMAT)}</h4>
            </IonText>
          </IonLabel>
          <IonButton
            slot="end"
            onClick={() => {
              setShowDatePicker(!showDatePicker)
            }}
          >
            {showDatePicker ? 'Hide picker' : 'Pick manually'}
          </IonButton>
        </IonItem>
        <IonItem lines="none">
          <IonIcon icon={calendarOutline} slot="start" />
          <IonLabel className="ion-text-wrap">
            <IonText>
              <p>End date</p>
              <h4>{endDate.toFormat(DATE_DISPLAY_FORMAT)}</h4>
            </IonText>
          </IonLabel>
        </IonItem>
        <IonItem lines="none">
          <IonIcon icon={receiptOutline} slot="start" />
          <IonLabel className="ion-text-wrap">
            <IonText>
              <p>Policy number</p>
              <h4>{policyNumber} </h4>
            </IonText>
          </IonLabel>
        </IonItem>

        <IonItemDivider />
        <IonButton expand="block" type="submit" onClick={handleSave}>
          Save policy
        </IonButton>
      </IonContent>
    </IonPage>
  )
}

const EditCustomerModal = ({ onDismiss, onSave, saving, customer }) => {
  /**
   * how do we set error message for the errors obj form formState?
   *
   * we should show activness of forms, like when the cursor is there
   */
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      phone: customer.phone || '+254',
      firstName: customer.firstName,
      lastName: customer.lastName,
      officialId: customer.officialId,
      sacco: customer.sacco,
    },
    reValidateMode: 'onChange',
    // shouldFocusError: true,
    // shouldUseNativeValidation: true,
    // delayError: true,
  })

  const onSubmit = data => {
    onSave(data)
  }

  const isPhoneNumber = useCallback(
    suppliedPhone => {
      if (!suppliedPhone || suppliedPhone === '') {
        return true
      }
      let validationResult = phone(suppliedPhone)
      if (validationResult.isValid) {
        setValue('phone', validationResult.phoneNumber, { shouldDirty: true, shouldValidate: false, shouldTouch: true })
        return true
      } else {
        validationResult = phone(suppliedPhone, { country: 'ke' })
        if (validationResult.isValid) {
          setValue('phone', validationResult.phoneNumber, {
            shouldDirty: true,
            shouldValidate: false,
            shouldTouch: true,
          })
        }
        return validationResult.isValid
      }
    },
    [setValue]
  )

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="secondary">
            <IonButton
              color="secondary"
              onClick={() => {
                onDismiss({})
              }}
            >
              <IonIcon slot="icon-only" icon={close} />
            </IonButton>
          </IonButtons>
          <IonTitle>Update Customer</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonItemDivider />

          <IonItem>
            <IonLabel position="stacked" color={errors.firstName ? 'danger' : undefined}>
              First name
            </IonLabel>
            <Controller
              render={field => {
                return (
                  <IonInput
                    name={field.field.name}
                    placeholder="First name"
                    color="secondary"
                    autocomplete="off"
                    value={field.field.value}
                    onIonChange={e => {
                      setValue(field.field.name, e.detail.value, {
                        shouldValidate: true,
                        shouldTouch: true,
                        shouldDirty: true,
                      })
                    }}
                  />
                )
              }}
              control={control}
              name="firstName"
              rules={{ required: true }}
            />
          </IonItem>

          <IonItem>
            <IonLabel position="stacked" color={errors.lastName ? 'danger' : undefined}>
              Last name
            </IonLabel>
            <Controller
              render={field => {
                return (
                  <IonInput
                    name={field.field.name}
                    placeholder="Last name"
                    color="secondary"
                    autocomplete="off"
                    value={field.field.value}
                    onIonChange={e => {
                      setValue(field.field.name, e.detail.value, {
                        shouldValidate: true,
                        shouldTouch: true,
                        shouldDirty: true,
                      })
                    }}
                  />
                )
              }}
              control={control}
              name="lastName"
              rules={{ required: true }}
            />
          </IonItem>

          <IonItem>
            <IonLabel position="stacked" color={errors.officialId ? 'danger' : undefined}>
              Official Id
            </IonLabel>
            <Controller
              render={field => {
                return (
                  <IonInput
                    name={field.field.name}
                    color="secondary"
                    placeholder="Id"
                    autocomplete="off"
                    value={field.field.value}
                    onIonChange={e => {
                      setValue(field.field.name, e.detail.value, {
                        shouldValidate: true,
                        shouldTouch: true,
                        shouldDirty: true,
                      })
                    }}
                  />
                )
              }}
              control={control}
              name="officialId"
              rules={{ required: true }}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked" color={errors.sacco ? 'danger' : undefined}>
              Sacco
            </IonLabel>
            <Controller
              render={field => {
                return (
                  <IonInput
                    name={field.field.name}
                    color="secondary"
                    placeholder="Sacco name"
                    autocomplete="off"
                    value={field.field.value}
                    onIonChange={e => {
                      setValue(field.field.name, e.detail.value, {
                        shouldValidate: true,
                        shouldTouch: true,
                        shouldDirty: true,
                      })
                    }}
                  />
                )
              }}
              control={control}
              name="sacco"
              rules={{ required: false }}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked" color={errors.phone ? 'danger' : undefined}>
              Phone (with country code +254)
            </IonLabel>
            <Controller
              render={field => {
                return (
                  <IonInput
                    name={field.field.name}
                    color="secondary"
                    placeholder="User phone number"
                    autocomplete="off"
                    type="tel"
                    value={field.field.value}
                    onIonChange={e => {
                      setValue(field.field.name, e.detail.value, {
                        shouldValidate: true,
                        shouldTouch: true,
                        shouldDirty: true,
                      })
                    }}
                  />
                )
              }}
              control={control}
              name="phone"
              rules={{ validate: isPhoneNumber }}
            />
          </IonItem>

          <IonItemDivider />
          <IonButton expand="block" type="submit" disabled={saving}>
            {saving ? 'Saving...' : 'Save changes'}
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  )
}

const CustomerDetails = () => {
  const user = useStore(state => state.user)
  const getGasStationById = useStore(state => state.getGasStationById)
  const getCustomerByPhone = useCustomerStore(state => state.getCustomerByPhone)
  const getCustomerByOfficialId = useCustomerStore(state => state.getCustomerByOfficialId)

  const [saving, setSaving] = useState()
  const { balanceId } = useParams()

  const [balance] = useDocumentData(doc(balancesCollectionRef, balanceId), { idField: 'id' })

  const [customer] = useDocumentData(balanceId ? doc(customersCollectionRef, balanceId) : null, {
    idField: 'id',
  })

  const transactionsQuery = useMemo(() => {
    return customer?.id
      ? query(transactionsCollectionRef, where('customerId', '==', customer.id), orderBy('createdAt', 'desc'))
      : undefined
  }, [customer?.id])

  const [transactions, areTransactionsLoading] = useCollectionData(transactionsQuery, {
    idField: 'id',
  })

  const policiesQuery = useMemo(() => {
    //? query(policiesCollectionRef, where('customerId', '==', customer.id), orderBy('endAt', 'desc'))
    return customer?.id
      ? query(policiesCollectionRef, where('customerId', '==', customer.id), orderBy('endDate', 'desc'))
      : undefined
  }, [customer?.id])

  const [policies, arePoliciesLoading] = useCollectionData(policiesQuery, {
    idField: 'id',
  })

  const minStartDate = useMemo(() => {
    let toReturn = DateTime.local().setZone(defaultTimezone).plus({ days: 1 }).startOf('day')
    if (policies?.length > 0) {
      const parsedLastPolicyEndDate = DateTime.fromJSDate(policies[0].endDate.toDate()).setZone(defaultTimezone)
      const dayAfterLastPolicyEndDate = parsedLastPolicyEndDate.plus({ days: 1 }).startOf('day')

      toReturn = toReturn > dayAfterLastPolicyEndDate ? toReturn : dayAfterLastPolicyEndDate
    }
    return toReturn
  }, [policies])

  const [presentToast] = useIonToast()

  const handleSave = async data => {
    try {
      setSaving(true)
      await addDoc(policiesCollectionRef, {
        ...data,
        fuelAmountDeducted: defaultFuelAmountCoveredByInsuranPolicy,
        customerId: customer?.id,
        createdAt: serverTimestamp(),
        createdBy: user?.id,
      })
      handleDismiss()
      presentToast({
        color: 'success',
        message: 'Insurance policy added successfully',
        duration: 2000,
      })
    } catch (err) {
      presentToast({
        color: 'danger',
        message: 'Something went wrong, please try again',
        duration: 2000,
      })
    } finally {
      setSaving(false)
    }
  }

  const handleDismiss = () => {
    dismiss()
  }

  const [presentLoader, dismissLoader] = useIonLoading()

  const handleEditCustomerModalDismiss = () => {
    dismissEditModal()
  }

  const handleEditCustomer = useCallback(
    async editedData => {
      try {
        presentLoader({
          message: 'Saving...',
        })

        let doesUserExist = false
        let whichProvider = ''
        if (editedData.officialId !== customer.officialId) {
          doesUserExist = getCustomerByOfficialId(editedData.officialId)
          whichProvider = 'officialId'
        }
        if (editedData.phone !== customer.phone) {
          doesUserExist = getCustomerByPhone(editedData.phone)
          whichProvider = 'phone'
        }
        if (doesUserExist) {
          setTimeout(() => {
            presentToast({
              color: 'danger',
              message: `User with this ${whichProvider} already exists. `,
              duration: 4000,
              position: 'bottom',
            })
            dismissLoader()
          }, 500)
        } else {
          let customerRef = doc(customersCollectionRef, customer.id)
          let dataToBeSaved = {
            ...editedData,
            sacco: editedData.sacco || '',
            fullName: `${editedData.firstName} ${editedData.lastName}`,
          }

          await updateDoc(customerRef, dataToBeSaved)
          handleEditCustomerModalDismiss()
          setTimeout(() => {
            presentToast({
              color: 'success',
              message: 'Customer edited',
              duration: 2000,
            })
            dismissLoader()
          }, 500)
        }
      } catch (err) {
        console.log({ err })
        dismissLoader()
        presentToast({
          color: 'danger',
          message: 'Error! Please try again',
          duration: 2000,
          position: 'bottom',
        })
      }
    },
    [
      customer?.id,
      customer?.officialId,
      customer?.phone,
      dismissLoader,
      getCustomerByOfficialId,
      getCustomerByPhone,
      handleEditCustomerModalDismiss,
      presentLoader,
      presentToast,
    ]
  )

  const handleEditTransaction = useCallback(
    async (transactionId, editedData, modalDismissFn) => {
      try {
        presentLoader({
          message: 'Updating transaction...',
        })

        let transactionRef = doc(transactionsCollectionRef, transactionId)
        let dataToBeSaved = { ...editedData, updatedBy: user?.id, updatedAt: serverTimestamp() }

        await updateDoc(transactionRef, dataToBeSaved)
        setTimeout(() => {
          presentToast({
            color: 'success',
            message: 'Transaction updated',
            duration: 2000,
          })
          modalDismissFn()
        }, 500)
      } catch (err) {
        console.log({ err })

        presentToast({
          color: 'danger',
          message: 'Error! Please try again',
          duration: 2000,
          position: 'bottom',
        })
      } finally {
        setTimeout(() => {
          dismissLoader()
        }, 1000)
      }
    },
    [dismissLoader, presentLoader, presentToast, user?.id]
  )

  const [presentEditModal, dismissEditModal] = useIonModal(EditCustomerModal, {
    onDismiss: handleEditCustomerModalDismiss,
    onSave: handleEditCustomer,
    customer: customer,
  })
  // handle loader flow in modal ?
  const [presentAddPolicyModal, dismiss] = useIonModal(AddPolicyModal, {
    onDismiss: handleDismiss,
    onSave: handleSave,
    officialId: customer?.officialId,
    minStartDate,
  })

  const groupedTransactions = useMemo(() => {
    return _.groupBy(transactions, el => {
      return el?.createdAt?.toDate()?.toLocaleDateString()
    })
  }, [transactions])

  const totalFuelAmount = useMemo(() => {
    if (!transactions) {
      return 0
    } else {
      const calculatedValue = transactions?.reduce((total, transaction) => {
        total = total + transaction.fuelAmount
        return total
      }, 0)
      return calculatedValue.toFixed(2)
    }
  }, [transactions])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Customer Details</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {/* <AddTransactionForm fuelPrice={currentGasStation?.currentFuelPrice} onSave={handleSave} /> */}
        <IonCard>
          <IonItem lines="none">
            <IonText>
              <h1>{customer?.fullName}</h1>{' '}
            </IonText>
            <IonButton
              slot="end"
              onClick={() => {
                presentEditModal()
              }}
            >
              Edit
            </IonButton>
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={phonePortraitOutline} slot="start" />
            <IonLabel className="ion-text-wrap">
              <IonText>
                <p>Phone number</p>
                <h4>{customer?.phone ? formatPhoneNumberIntl(customer?.phone) : ''}</h4>
              </IonText>
            </IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={idCardOutline} slot="start" />
            <IonLabel className="ion-text-wrap">
              <IonText>
                <p>Official Id</p>
                <h4>{customer?.officialId}</h4>
              </IonText>
            </IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={storefrontOutline} slot="start" />
            <IonLabel className="ion-text-wrap">
              <IonText>
                <p>Sacco</p>
                <h4>{customer?.sacco ? customer?.sacco : '---'}</h4>
              </IonText>
            </IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={cashOutline} slot="start" />
            <IonLabel className="ion-text-wrap">
              <IonText>
                <p>Current balance</p>
                <h4 className="bold">{balance ? litresLocale.format(balance.currentFuelBalance) : 'Loading...'}</h4>
              </IonText>
            </IonLabel>
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={cashOutline} slot="start" />
            <IonLabel className="ion-text-wrap">
              <IonText>
                <p>Total fuel purchased</p>
                <h4 className="bold">{litresLocale.format(totalFuelAmount)}</h4>
              </IonText>
            </IonLabel>
          </IonItem>
        </IonCard>
        <IonListHeader mode="ios" className="has-add-list-header center-wrapper">
          <IonLabel>{arePoliciesLoading ? 'Loading policies...' : 'Insurance policies'}</IonLabel>
          <IonButton
            size="small"
            fill="solid"
            color="primary"
            onClick={() => {
              presentAddPolicyModal()
            }}
          >
            <IonIcon color="light" icon={add} />
            <IonText color="light">Add policy</IonText>
          </IonButton>
        </IonListHeader>
        <div className="list-wrapper">
          <IonList>
            {policies?.map(policy => (
              <PolicyCard key={policy.id} policy={policy} />
            ))}
          </IonList>
        </div>

        <IonListHeader mode="ios" className="has-add-list-header center-wrapper">
          <IonLabel>{areTransactionsLoading ? 'Loding transactions...' : 'Transactions'}</IonLabel>
        </IonListHeader>

        <div className="list-wrapper">
          <IonList>
            {Object.keys(groupedTransactions).map(group => (
              <Fragment key={group}>
                <IonListHeader lines="inset" key={group}>
                  <IonLabel color="primary">{group}</IonLabel>
                </IonListHeader>
                {groupedTransactions[group]?.map(transaction => (
                  <TransactionCard
                    key={transaction.id}
                    transaction={transaction}
                    gasStation={getGasStationById(transaction.gasStationId)}
                    onTransactionUpdate={handleEditTransaction}
                  />
                ))}
              </Fragment>
            ))}
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default CustomerDetails
