import { useMemo, useState, useEffect, useRef, useCallback } from 'react'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonLabel, IonItem, IonText } from '@ionic/react'

import { formatPhoneNumberIntl } from 'react-phone-number-input/mobile'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Virtuoso } from 'react-virtuoso'

import { query, where, doc, getDoc, orderBy } from 'firebase/firestore'

import { balancesCollectionRef, customersCollectionRef } from '../services/firebase'

const NEEDED_BALANCE = 90.0

const locale = Intl.NumberFormat('ke-KE', {
  style: 'unit',
  unit: 'liter',
  unitDisplay: 'long',
})

const Balances = () => {
  const [lastUpdated, setLastUpdated] = useState(new Date())
  const customers = useRef({})
  const balancesQuery = useMemo(() => {
    return query(
      balancesCollectionRef,
      where('currentFuelBalance', '>=', NEEDED_BALANCE),

      orderBy('currentFuelBalance', 'desc')
    )
  }, [])

  const [balances] = useCollectionData(balancesQuery, {
    idField: 'id',
  })

  useEffect(() => {
    balances?.forEach(balance => {
      const { customerId } = balance
      if (!customers.current[customerId]) {
        const customerRef = doc(customersCollectionRef, customerId)
        getDoc(customerRef).then(customerDoc => {
          if (customerDoc.exists()) {
            customers.current[customerDoc.id] = customerDoc.data()
            setLastUpdated(new Date())
          }
        })
      }
    })
  }, [balances])

  const getCustomer = useCallback(customerId => {
    return customers.current[customerId]
  }, [])

  const getBalance = useCallback(
    index => {
      return balances[index]
    },
    [balances]
  )

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Todo</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <Virtuoso
          style={{ height: '100%' }}
          totalCount={balances ? balances.length : 0}
          itemContent={index => {
            return (
              <IonItem key={getBalance(index).id} routerLink={`/app/todo/${getBalance(index).customerId}`}>
                <IonLabel className="ion-text-wrap">
                  <IonText color="dark">
                    <h2>{getCustomer(getBalance(index).customerId)?.fullName}</h2>
                  </IonText>
                  <IonText color="medium">
                    <h3>
                      {getCustomer(getBalance(index).customerId)
                        ? formatPhoneNumberIntl(getCustomer(getBalance(index).customerId)?.phone)
                        : ''}
                    </h3>
                  </IonText>
                  <IonText color="primary">
                    <h4 className="bold">{locale.format(getBalance(index).currentFuelBalance)}</h4>
                  </IonText>
                </IonLabel>
              </IonItem>
            )
          }}
        />
        {/* <IonList key={lastUpdated}>
          {balances?.map(balance => (
            <IonItem key={balance.id} routerLink={`/app/todo/${balance.customerId}`}>
              <IonLabel className="ion-text-wrap">
                <IonText color="dark">
                  <h2>{getCustomer(balance.customerId)?.fullName}</h2>
                </IonText>
                <IonText color="medium">
                  <h3>
                    {getCustomer(balance.customerId)
                      ? formatPhoneNumberIntl(getCustomer(balance.customerId).phone)
                      : ''}
                  </h3>
                </IonText>
                <IonText color="primary">
                  <h4 className="bold">{locale.format(balance.currentFuelBalance)}</h4>
                </IonText>
              </IonLabel>
            </IonItem>
          ))}
        </IonList> */}
      </IonContent>
    </IonPage>
  )
}

export default Balances
