import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonPage,
  IonSpinner,
  IonCardTitle,
  IonCardSubtitle,
  useIonToast,
} from '@ionic/react'
import { useEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'

import { auth } from '../services/firebase'
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'

export default function SignInWithEmailLink() {
  const { path } = useRouteMatch()
  const history = useHistory()
  const [presentToast] = useIonToast()

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      var email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        email = window.prompt('Please provide your email for confirmation')
      }

      signInWithEmailLink(auth, email, window.location.href)
        .then(result => {
          window.localStorage.removeItem('emailForSignIn')
          history.replace('/')
        })
        .catch(error => {
          presentToast({
            color: 'danger',
            message: `Something went wrong. Please try to sign-in again`,
            duration: 2000,
          })
          history.replace('/login')
          console.log(error)
        })
    }
  }, [path, history, presentToast])

  return (
    <IonPage>
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Signing you in</IonCardTitle>
            <IonCardSubtitle>Give us a second...</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonSpinner />
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  )
}
