import { getAuth } from 'firebase/auth'
import { getFirestore, collection, documentId } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import firebase from 'firebase/compat/app'
// import { initializeApp } from "firebase/app"

import 'firebase/compat/auth'
import CONFIG from '../config/config'
let firebaseApp
if (!firebaseApp) {
  firebaseApp = firebase.initializeApp(CONFIG.firebaseConfig)
}

export const COLLECTIONS = {
  transactions: 'transactions',
  gasStations: 'gasStations',
  customers: 'customers',
  users: 'users',
  balances: 'balances',
  insurancePolicies: 'insurancePolicies',
}

export const firestoreDb = getFirestore(firebaseApp)
export const functions = getFunctions(firebaseApp)
export const doesUserExistFBFunction = httpsCallable(functions, 'doesUserExist')
export const doesAdminUserExistFBFunction = httpsCallable(functions, 'doesAdminUserExist')
export const transactionsCollectionRef = collection(firestoreDb, COLLECTIONS.transactions)

export const customersCollectionRef = collection(firestoreDb, COLLECTIONS.customers)
export const balancesCollectionRef = collection(firestoreDb, COLLECTIONS.balances)
export const policiesCollectionRef = collection(firestoreDb, COLLECTIONS.insurancePolicies)

export const gasStationsCollectionRef = collection(firestoreDb, COLLECTIONS.gasStations)
export const usersCollectionRef = collection(firestoreDb, COLLECTIONS.users)

export const auth = getAuth(firebaseApp)
export { firebaseApp, firebase, documentId }
